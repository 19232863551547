<template>
  <div class="history">
    <!-- card -->
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>详情：{{ carNum }}</span>
        <el-button
          class="tz-r tz-create-btn"
          type="primary"
          round
          @click="jump('/carstatics')"
          >返回</el-button
        >
      </div>

      <!-- 筛选 -->
      <el-row>
        <!-- 时间选择 -->
        <el-col :span="7">
          <el-date-picker
            v-model="pickerDate"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :editable="false"
            unlink-panels
            :picker-options="pickerOptions"
            @change="handleDateChange"
          >
          </el-date-picker>
        </el-col>

        <!-- 搜索 -->
        <!-- <el-col :span="1">
          <el-button
            icon="el-icon-search"
            type="primary"
            plain
            @click="search"
          ></el-button>
        </el-col> -->

        <!-- 下载 -->
        <!-- <el-col :span="1">
          <el-button
            v-if="tableData.length != 0"
            icon="el-icon-download"
            type="info"
            plain
            :loading="downloadLoading"
            @click="download"
          ></el-button>
        </el-col> -->
      </el-row>
      <!-- 筛选 -->

      <!-- 表格 -->
      <TzTable :tableData="tableData" :columns="columns" />
      <!-- <el-table :data="tableData" border style="width: 100%" ref="table">
        <el-table-column
          v-for="(item, i) in column"
          :key="i"
          :prop="item.prop"
          :label="item.label"
          sortable
        >
        </el-table-column>
        <el-table-column
          v-for="(item, i) in columns"
          :key="i + 'i'"
          :prop="item.prop"
          :label="item.label"
          sortable
        >
        </el-table-column>
      </el-table> -->
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
// import tableData from '~mock/users/history'
// import fileDownload from 'js-file-download'
import TzTable from '@/components/tz-table/Index.vue'
import { carInfo } from '@/api/workinghours'
export default {
  components: {
    TzTable
  },

  data() {
    return {
      downloadLoading: false,
      carNum: '',
      page: 1, //当前页
      limit: 10, //
      total: '',
      pickerDate: [], //日期 默认上个月
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },

      // 表格动态列
      columns: [
        {
          field: 'userName',
          title: '驾驶员'
        },
        {
          field: 'stopNum',
          title: '违停次数'
          // width: '300px'
        },
        {
          field: 'travelNum',
          title: '行驶里程（公里）'
          // width: '300px'
        },
        {
          field: 'travelTime',
          title: '时间'
        }
      ],
      // 表格数据
      tableData: [],
      equipmentForm: {
        // name: '',
        // region: '',
        // date1: '',
        // date2: ''
      }
    }
  },
  created() {
    this.setups()
  },
  methods: {
    setups() {
      console.log(this.$route.query)
      let dataId = this.$route.query
      this.carNum = dataId.carNum
      this.pickerDate[0] = dataId.startTime || ''
      this.pickerDate[1] = dataId.endTime || ''
    },
    // 获取上个月第一天
    // getLastMonthFirstDay() {
    //   let date = new Date()
    //   date.setDate(0)
    //   var y = date.getFullYear() //获取年份
    //   var m = date.getMonth() + 1 //获取月份
    //   m = m < 10 ? '0' + m : m //月份补 0
    //   return [y, m, '01'].join('-')
    // },
    // 获取上个月最后一天
    // getLastMonthLastDay() {
    //   let date = new Date()
    //   date.setDate(0)
    //   var y = date.getFullYear() //获取年份
    //   var m = date.getMonth() + 1 //获取月份
    //   var d = new Date(y, m, 0).getDate() //获取当月最后一日
    //   m = m < 10 ? '0' + m : m //月份补 0
    //   d = d < 10 ? '0' + d : d //日数补 0
    //   return [y, m, d].join('-')
    // },

    handleSizeChange(val) {
      this.limit = val
      this.pageListInfoFn()
    },
    handleCurrentChange(val) {
      this.page = val
      this.pageListInfoFn()
    },
    //选择日期事件
    handleDateChange(dialogPickerDate) {
      console.log(dialogPickerDate)
      if (dialogPickerDate == null) {
        this.pickerDate = []
      }
      this.page = 1
      this.pageListInfoFn()
    },

    //获取数据
    pageListInfoFn() {
      var data = {
        carNum: this.carNum,
        startTime: this.pickerDate[0] || '',
        endTime: this.pickerDate[1] || '',
        pageNum: this.page,
        pageSize: this.limit
      }
      carInfo(data).then((res) => {
        if (res.code == 200) {
          this.total = res.data.total
          this.tableData = res.data.records
        }
      })
    },
    // 点击搜索
    search() {
      this.pageListInfoFn()
    }
    //下载
    // download() {
    //   this.downloadLoading = true
    //   var data = {
    //     startDt: this.pickerDate[0] || '',
    //     endDt: this.pickerDate[1] || '',
    //     type: this.type
    //   }
    //   workloadExport(data).then((res) => {
    //     fileDownload(res, '工作量统计.xlsx')
    //   })
    //   this.downloadLoading = false
    // }
  },
  mounted() {
    this.pageListInfoFn()
  }
}
</script>

<style lang="scss" scoped>
.clearfix {
  display: flex;
  justify-content: space-between;
}
</style>
