<template>
  <div class="create">
    <!-- card -->
    <el-card class="box-card">
      <!-- card header -->
      <div slot="header" class="clearfix">
        <span>创建数据权限</span>
        <el-button
          class="tz-r tz-create-btn"
          type="primary"
          round
          @click="jump('/datarights')"
          >返回</el-button
        >
      </div>
      <!-- card header -->
      <!-- card content -->
      <el-form
        :model="formData"
        :rules="rules"
        label-width="100px"
        ref="formData"
      >
        <el-form-item label="用户账号" prop="userId">
          <el-select
            v-model="formData.userId"
            @focus="focusInput"
            placeholder="请选择用户账号"
          >
            <el-option
              v-for="item in formData.userPayload"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否选择全部" v-if="isAllShow">
          <!-- <el-input v-model="formData.status"></el-input> -->
          <el-radio-group
            v-model="formData.rightisAll"
            size="small"
            @change="isAllChange"
          >
            <el-radio :label="1" border>是</el-radio>
            <el-radio :label="0" border>否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="权限内容" prop="rightText" v-if="isAllSure">
          <el-row class="rightType">
            <!-- 区域 -->
            <el-col :span="4" v-if="isREGION">
              <el-input
                placeholder="请输入区域"
                v-model="authValue['REGION'].rightText"
              >
              </el-input>
            </el-col>
            <!-- 租赁单位  -->
            <el-col :span="4" v-if="isLESSOR">
              <el-select
                clearable
                v-model="authValue['LESSOR'].rightText"
                @change="changeUnit"
                multiple
                filterable
                placeholder="请选择租赁单位"
              >
                <el-option
                  v-for="item in unitCompanys"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>

            <!-- 甲方单位 -->
            <el-col :span="4" v-if="isAPPLIER">
              <el-select
                clearable
                v-model="authValue['APPLIER'].rightText"
                @change="changePartyName"
                multiple
                filterable
                placeholder="请选择甲方单位"
              >
                <el-option
                  v-for="item in firstCompanys"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <!-- 工地 -->
            <el-col :span="4" v-if="isWORKYARD">
              <el-select
                clearable
                v-model="authValue['WORKYARD'].rightText"
                @change="changeProject"
                multiple
                filterable
                allow-create
                placeholder="请选择工地"
              >
                <el-option
                  v-for="item in projectNames"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- <el-pagination
              layout="prev, pager, next"
              small
              :total="total"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
            >
            </el-pagination> -->

        <!-- 表单按钮 -->
        <div v-if="formBtns && formBtns.length" class="button-group">
          <el-button
            v-for="(item, index) in formBtns"
            :key="index"
            :type="item.type"
            :style="{ width: item.width || '70px' }"
            class="form-button"
            @click="submitFn(item.content)"
          >
            <!-- <i v-if="item.icon" :class="item.icon"></i> -->
            <span> {{ item.content }}</span>
          </el-button>
        </div>
      </el-form>
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import { brequest } from '@/utils/brequest'
import { DATA_RIGHT_TYPE } from '@/utils/params'
import { addUserDataRight } from '@/api/users'
import { queryPartyaNames, queryUnit, queryProjectName } from '@/api/inspect'
export default {
  methods: {
    submitFn(content) {
      console.log(this.formData, this.formData.rightpayload)
      this.rightTextList = []

      if (content === '重置') {
        this.rightTextList = []
        this.$refs.formData.resetFields()
      } else {
        // 语法：this.$refs.ref属性值.validate(isSuccess=> {})
        // 作用：表单提交也验证下
        this.$refs.formData.validate((isSuccess) => {
          if (isSuccess) {
            this.$loading({
              text: '数据权限添加中'
            })
            // 有全部并选择全部
            if (this.isAllShow && this.formData.rightisAll == 1) {
              let allList = {
                userId: this.formData.userId, //用户帐号
                rightType: 'ALL', //权限类型
                rightText: '' //权限内容
              }
              this.rightTextList.push(allList)
            } else {
              let arrTemp = Object.keys(this.authValue).map((el) => {
                return {
                  userId: this.formData.userId,
                  rightType: el,
                  rightText: this.authValue[el].rightText.toString()
                }
              })
              this.rightTextList = arrTemp.filter((ele) => ele.rightText)
            }
            console.log(this.rightTextList, '权限内容集合')
            // 查看权限内容是否填完整
            // 权限无ALL必填
            if (!this.isAllShow) {
              if (
                this.formData.rightpayload.length != this.rightTextList.length
              ) {
                this.$message({
                  message: '权限内容填写不完整',
                  type: 'error'
                })
                return
              }
            }
            this.addUserDataRightFn()
          }
        })
      }
    },
    addUserDataRightFn() {
      addUserDataRight(this.rightTextList).then((res) => {
        console.log(res.data, '创建')
        if (res.data.code == 0) {
          this.submited = true
          this.$message({
            message: '数据权限添加成功',
            type: 'success'
          })
          this.$router.go(-1)
        } else if (res.data.code == -1) {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
      setTimeout(() => {
        this.$loading().close()
      }, 1000)
    },
    // 查询用户账户
    async queryAllUserInfo() {
      let ret = await brequest({
        url: '/user/queryAllUserInfoDate'
      })
      // this.formConfig.find((rec) => rec.field === 'userId').payload = ret.map(
      this.formData.userPayload = ret.map((rec) => {
        return {
          value: rec.id,
          label: rec.loginName
        }
      })
    },
    // 用户账户点击
    focusInput(focus) {
      console.log(focus)
      if (this.formData.userPayload.length == 0) {
        this.$message({
          message: '暂无用户，请先创建用户',
          type: 'error'
        })
      }
    },
    // 查询权限类型
    async queryAllauthType() {
      let ret = await brequest({
        url: '/user/queryDataRight'
      })
      // this.formConfig.find((rec) => rec.field === 'userId').payload = ret.map(
      console.log(ret)
      // 查看用户有的权限 有“全部”可以选择
      let newArr = ret.map((type) => {
        return {
          value: type.rightType,
          label: type.rightType
        }
      })
      // 去重
      let hash = {}
      let rightTypeArr = newArr.reduce((item, next) => {
        hash[next.value] ? '' : (hash[next.value] = true && item.push(next))
        return item
      }, [])
      // 已知权限跟所有权限根据value合并
      DATA_RIGHT_TYPE.forEach((oldel) => {
        rightTypeArr.forEach((newel) => {
          if (newel.value == oldel.value) {
            newel.label = oldel.label
          }
        })
        // 当前用户权限
        this.formData.rightpayload = rightTypeArr
      })
      let includeAll = rightTypeArr.find((item) => {
        // 权限是否有“全部”
        return item.value == 'ALL'
      })
      if (includeAll) {
        // 包含全部让权限输入框下拉框都显示
        DATA_RIGHT_TYPE.forEach((auth) => {
          this['is' + auth.value] = true
          console.log('is' + auth.value)
        })
        this.isAllShow = true
      } else {
        this.isAllSure = true
        this.isAllShow = false
        // 不包含全部让输入框下拉框按照已有权限显示
        rightTypeArr.forEach((auth) => {
          this['is' + auth.value] = true
          console.log('is' + auth.value)
        })
      }
    },
    async selectChange(val) {
      // console.log(val)
      console.log(this.formData.rightType)
      this.formData.rightText = ''
      this.isAllShow = false
      // let url = ''
      this.formData.textPayload = []
      if (val == 'ALL') {
        this.isAllShow = true
      } else if (val == 'APPLIER') {
        //申请单位
        this.url = '/inspect/queryPartyaNames'
      } else if (val == 'LESSOR') {
        //租赁单位
        this.url = '/inspect/queryUnit'
      } else if (val == 'WORKYARD') {
        //工地
        this.url = '/inspect/queryProjectName'
      }
      if (val == 'APPLIER' || val == 'LESSOR' || val == 'WORKYARD') {
        this.select()
      }
    },
    async select() {
      let data = { pageNum: this.pageNum, pageSize: this.pageSize, name: '' }
      let ret = await brequest({
        url: this.url,
        headers: {
          'content-type': 'application/json'
        },
        dataType: 'json',
        data
      })
      console.log(ret)
      this.formData.textPayload = ret
      // this.total = ret.total
      // for (let index = 0; index < ret.records.length; index++) {
      //   let obj = {}
      //   obj['value'] = ret.records[index]
      //   obj['label'] = ret.records[index]
      //   this.formData.textPayload.push(obj)
      // }
    },
    // 是否选择全部
    isAllChange(isAll) {
      console.log(isAll, '是否全部')
      if (isAll == 1) {
        this.isAllSure = false
        this.formData.rightType = 'ALL'
      } else if (isAll == 0) {
        this.formData.rightType = ''

        this.isAllSure = true
      }
    },
    //甲方单位
    queryPartyaNames() {
      var data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name: ''
      }
      queryPartyaNames(data).then((res) => {
        console.log(res.data, '甲方单位')
        this.firstCompanys = res.data
      })
    },

    //租赁单位
    queryUnit() {
      var data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name: ''
      }
      queryUnit(data).then((res) => {
        console.log(res.data, '租赁单位')
        this.unitCompanys = res.data
        // this.queryProjectName() //工地
      })
    },
    //工地
    queryProjectName() {
      var data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name: ''
      }
      queryProjectName(data).then((res) => {
        console.log(res.data, '工地')
        this.projectNames = res.data
        // this.queryUnit() //租赁单位
      })
    },
    //甲方下拉框
    changePartyName(firstValue) {
      console.log(firstValue)
      this.authValue['APPLIER'].rightText = firstValue
    },

    //租赁下拉框
    changeUnit(unitValue) {
      this.authValue['LESSOR'].rightText = unitValue
    },
    //工地下拉框
    changeProject(projectName) {
      this.authValue['WORKYARD'].rightText = projectName
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.select()
    }
  },

  mounted() {
    this.queryAllUserInfo()
    this.queryAllauthType()
    this.queryPartyaNames() //甲方单位
    this.queryUnit() //租赁单位
    this.queryProjectName() //工地
  },

  data() {
    return {
      // 表单配置
      // form: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      url: '',
      isAllShow: false,
      isAllSure: false,
      isAPPLIER: false,
      isLESSOR: false,
      isREGION: false,
      isWORKYARD: false,
      // 甲方单位
      firstCompanys: [],
      // 租赁单位
      unitCompanys: [],
      // 工地
      projectNames: [],
      // 权限内容集合
      rightTextList: [],
      authValue: {
        // 区域
        REGION: {
          rightText: ''
        },
        // 甲方单位
        APPLIER: {
          rightText: []
        },
        // 工地
        LESSOR: {
          rightText: []
        },
        WORKYARD: {
          rightText: []
        }
      },
      formData: {
        userId: '', //用户帐号
        userPayload: [], //帐号下拉
        rightType: '', //权限类型
        // rightpayload: DATA_RIGHT_TYPE, //权限下拉
        rightpayload: [], //权限下拉
        rightisAll: 1,
        rightText: '', //权限内容
        textPayload: []
      },
      rules: {
        userId: [{ required: true, message: '用户账号必填', trigger: 'blur' }],
        rightType: [
          { required: true, message: '权限类型必填', trigger: 'blur' }
        ],
        rightText: [{ required: false, trigger: 'blur' }]
      },
      formConfig: [
        {
          label: '权限内容',
          type: 'autocomplete',
          field: 'rightText',
          rules: [{ required: false, trigger: 'blur' }]
        }
      ],
      formBtns: [
        {
          content: '创建',
          width: '185px',
          type: 'primary'
        },
        {
          content: '重置',
          width: '185px',
          type: ''
        }
      ]
      // restaurants: []
    }
  }
}
</script>

<style lang="scss" scoped>
.button-group {
  display: flex;
  justify-content: center;
  .form-button {
    margin: 0 20px;
  }
}
.rightType {
  width: 100%;
  display: flex;
}
</style>
