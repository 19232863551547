<template>
  <div class="history">
    <!-- card -->
    <el-card class="box-card">
      <!-- card header -->
      <div slot="header">
        <el-row style="display: flex; align-items: center">
          <el-col :span="4">
            <span>设备扣分详情</span>
          </el-col>
          <el-col :span="11"> &nbsp; </el-col>
          <!-- 时间选择 -->
          <el-col :span="8">
            <el-date-picker
              v-model="pickerDate"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :editable="false"
              unlink-panels
              :picker-options="pickerOptions"
              @change="handleDateChange"
            >
            </el-date-picker>
          </el-col>
          <!-- 下载 -->
          <el-col :span="1">
            <el-button
              v-if="tableData.length != 0"
              icon="el-icon-download"
              type="info"
              :loading="downloadLoading"
              @click="download"
            ></el-button>
          </el-col>
        </el-row>
      </div>
      <!-- card header -->

      <!-- card content -->
      <!-- 筛选 -->
      <el-row>
        <!-- 维度下拉 -->
        <el-col :span="5">
          <el-select
            class="select"
            v-model="deviceValue"
            placeholder="请选择设备类型"
            @change="QueryDeviceType"
            filterable
          >
            <el-option
              v-for="item in deviceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <!-- 甲方单位 -->
          <el-select
            size="middle"
            multiple
            clearable
            v-model="firstValue"
            placeholder="请选择甲方单位"
            @change="changePartyName"
          >
            <el-checkbox-group v-model="firstValue">
              <el-option
                v-for="item in firstCompanys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <el-checkbox
                  style="pointer-events: none"
                  :label="item.value"
                ></el-checkbox>
              </el-option>
            </el-checkbox-group>
          </el-select>
        </el-col>

        <!-- 搜索 -->
        <el-col :span="1">
          <el-button
            icon="el-icon-search"
            type="primary"
            plain
            @click="search"
          ></el-button>
        </el-col>
        <el-col :span="7">&nbsp;</el-col>
      </el-row>
      <!-- 筛选 -->

      <!-- 表格 -->
      <!-- <TzTable :tableData="tableData" :columns="columns" /> -->
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          label="设备名称"
          prop="deviceName"
          sortable
        ></el-table-column>
        <el-table-column
          label="扣分问题"
          prop="defectcodeName"
          sortable
        ></el-table-column>
        <el-table-column
          label="严重等级"
          prop="levelcodeName"
          sortable
        ></el-table-column>
        <!-- <el-table-column label="扣分数" prop="score" sortable></el-table-column> -->
        <el-table-column
          label="状态"
          prop="statusName"
          sortable
        ></el-table-column>

        <el-table-column
          label="创建时间"
          prop="createDate"
          sortable
        ></el-table-column>
        <el-table-column label="扣分图片" prop="filepath" width="180">
          <template slot-scope="scope">
            <el-popover placement="left" width="250" trigger="hover">
              <div>
                <img
                  :key="index"
                  :src="getImgUrl(scope.row.filepath)"
                  style="width: 250px; height: 350px"
                />
              </div>

              <img
                :key="index"
                slot="reference"
                :src="getImgUrl(scope.row.filepath)"
                style="width: 100px; height: 100px"
                alt="image"
              />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- 分页 -->
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import table2excel from 'js-table2excel'
import { querySysData } from '@/api/detection'
import { queryPartyaNames, inspectQuestionInfo } from '@/api/inspect'
// import Template from '../../../../vueproject/src/components/template.vue'
export default {
  // components: { Template },
  data() {
    return {
      pickerDate: [], //日期 时间戳
      offset: '', //当前起始位置（page-1）*limit
      page: 1, //当前页
      limit: '10', //
      total: 0,
      deviceValue: '7', //默认塔式起重机
      deviceList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },

      downloadLoading: false,

      // 甲方单位
      firstCompanys: [],
      firstValue: [], //选中甲方
      // 表格列
      // columns: [
      //   { title: '名称', field: 'name' },
      //   { title: '得分', field: 'score' },
      //   {
      //     title: '图片',
      //     field: 'image',
      //     type: 'image'
      //   }
      // ],
      // 表格数据
      tableData: []
    }
  },
  methods: {
    // 默认时间
    defaultTime() {
      if (this.pickerDate.length == 0) {
        // 默认当前日期之前365天
        let sdate = new Date()
        let interval = -365 //间隔天数
        let caret = '-' //日期分隔符
        let strYear = sdate.getFullYear()
        let strDay = sdate.getDate()
        let strMonth = sdate.getMonth() + 1
        let patt1 = /^\d{4}-([0-1]?[0-9])-([0-3]?[0-9])$/ //判断输入的日期是否符合格式正则表达式
        if (!(sdate && typeof sdate == 'string' && patt1.test(sdate))) {
          sdate = new Date() //不满足日期的则使用当前年月日
        }
        interval = isNaN(parseInt(interval)) ? 0 : parseInt(interval) //若没有输入间隔，则使用当前日
        caret = caret && typeof caret == 'string' ? caret : ''
        let gdate = new Date(sdate).getTime() //获取指定年月日
        gdate = gdate + 1000 * 60 * 60 * 24 * interval //加减相差毫秒数
        let speDate = new Date(gdate) //获取指定好毫秒数时间
        let preYear = speDate.getFullYear()
        let preMonth = speDate.getMonth() + 1
        let preDay = speDate.getDate()
        preMonth = preMonth < 10 ? '0' + preMonth : preMonth
        preDay = preDay < 10 ? '0' + preDay : preDay
        let preDate = preYear + caret + preMonth + caret + preDay
        this.pickerDate = [preDate, strYear + '-' + strMonth + '-' + strDay]
      }
    },
    // 获取设备类型
    querySysDataFn() {
      querySysData().then((res) => {
        this.deviceList = res.data
      })
    },
    handleSizeChange(val) {
      this.limit = val
      // console.log(this.limit, this.page)
      this.inspectQuestionInfo(this.deviceValue)
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.page = val
      // console.log(this.limit, this.page)
      this.inspectQuestionInfo(this.deviceValue)
      // console.log(`当前页: ${val}`)
    },
    //选择日期事件
    handleDateChange(pickerDate) {
      console.log(pickerDate)
      if (pickerDate == null) {
        this.pickerDate = []
      }
      this.inspectQuestionInfo(this.deviceValue)
    },
    QueryDeviceType(el) {
      this.inspectQuestionInfo(el)
    },
    //甲方下拉框
    changePartyName(firstValue) {
      console.log(firstValue)
      this.firstValue = firstValue
    },
    //甲方单位
    queryPartyaNames() {
      queryPartyaNames().then((res) => {
        this.firstCompanys = res.data
      })
    },
    // 点击搜索
    search() {
      //数据请求
      this.inspectQuestionInfo(this.deviceValue)
    },
    // 扣分详情
    inspectQuestionInfo(deviceType) {
      // var page = this.page + 1
      var data = {
        deviceType, //设备类型
        partyaName: this.firstValue,
        startDt: this.pickerDate[0] || '',
        endDt: this.pickerDate[1] || '',
        offset: (this.page - 1) * this.limit,
        limit: this.limit
      }

      inspectQuestionInfo(data).then((res) => {
        console.log(res.data)
        this.total = res.data.total
        if (res.data.length == 0) {
          this.tableData = []
          this.$message({
            message: '暂无数据'
          })
        } else {
          // let datas = res.data.rows
          // this.tableData = datas.map((el) => {
          //   return {
          //     ...el,
          //     filepath: el.filepath.split(',')
          //   }
          // })
          // console.log(this.tableData, '==========')
          this.tableData = res.data.rows
        }

        console.log('扣分详情')
      })
    },
    download() {
      console.log(this.tableData, 'kkk')
      // this.downloadLoading = true
      const column = [
        {
          title: '设备名称',
          key: 'deviceName',
          type: 'text'
        },

        {
          title: '扣分问题',
          key: 'defectcodeName',
          type: 'text'
        },
        {
          title: '严重等级',
          key: 'levelcodeName',
          type: 'text'
        },
        // {
        //   title: '扣分数',
        //   key: 'score',
        //   type: 'text'
        // },
        {
          title: '状态',
          key: 'statusName',
          type: 'text'
        },
        {
          title: '创建时间',
          key: 'createDate',
          type: 'text'
        },
        {
          title: '扣分图片',
          key: 'filepath',
          type: 'image',
          width: 279,
          height: 107
        }
      ]
      let tableData = this.formatJson(this.tableData)
      table2excel(column, tableData, '设备扣分详情.xls')
    },
    // 图片地址拼接
    formatJson(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].filepath) {
          data[i].filepath = this.getImgUrl(data[i].filepath)
        }
      }
      return data
    }
  },
  created() {
    this.defaultTime()
    this.querySysDataFn() //设备类型
    this.queryPartyaNames()
  },
  mounted() {
    this.inspectQuestionInfo(this.deviceValue)
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-select__tags {
  flex-wrap: nowrap;
  overflow: auto;
}

/* 输入框最大宽度*/
::v-deep .el-select__tags-text {
  max-width: 90px;
}

/* 底部滚动条的高度*/
::v-deep .el-select__tags::-webkit-scrollbar {
  height: 4px !important;
}
</style>
