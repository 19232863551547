<template>
  <div class="history">
    <!-- card -->
    <el-card class="box-card">
      <!-- card header -->
      <!-- <div slot="header" class="clearfix">
        <span>得分统计</span>
      </div> -->
      <div slot="header">
        <el-row style="display: flex; align-items: center">
          <el-col :span="4">
            <span>得分统计</span>
          </el-col>
          <el-col :span="11"> &nbsp; </el-col>
          <!-- 时间选择 -->
          <el-col :span="8">
            <el-date-picker
              v-model="pickerDate"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :editable="false"
              unlink-panels
              :picker-options="pickerOptions"
              @change="handleDateChange"
            >
            </el-date-picker>
          </el-col>
          <!-- 下载 -->
          <el-col :span="1">
            <el-button
              v-if="tableData.length != 0"
              icon="el-icon-download"
              type="info"
              :loading="downloadLoading"
              @click="download"
            ></el-button>
          </el-col>
        </el-row>
      </div>
      <!-- card header -->

      <!-- card content -->
      <!-- 筛选 -->
      <el-row>
        <!-- 甲方单位 -->
        <el-col :span="4">
          <el-select
            size="middle"
            multiple
            clearable
            v-model="firstValue"
            placeholder="请选择甲方单位"
            @change="changePartyName"
          >
            <el-checkbox-group v-model="firstValue">
              <el-option
                v-for="item in firstCompanys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <el-checkbox
                  style="pointer-events: none"
                  :label="item.value"
                ></el-checkbox>
              </el-option>
            </el-checkbox-group>
          </el-select>
        </el-col>
        <!-- 维度下拉 -->
        <el-col :span="4">
          <el-select
            class="select"
            v-model="typeValue"
            placeholder="请选择维度"
            @change="changeType"
          >
            <el-option
              v-for="item in type"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>

        <!-- 搜索 -->
        <el-col :span="1">
          <el-button
            icon="el-icon-search"
            type="primary"
            plain
            @click="search"
          ></el-button>
        </el-col>
      </el-row>
      <!-- 筛选 -->

      <!-- 表格 -->
      <TzTable :tableData="tableData" :columns="columns" />

      <!-- 分页 -->
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pagenum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination> -->
      <!-- 分页 -->
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import TzTable from '@/components/tz-table/Index.vue'
import { queryPartyaNames, queryScore } from '@/api/inspect'
export default {
  components: {
    TzTable
  },
  data() {
    return {
      pickerDate: [], //日期 时间戳
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },

      downloadLoading: false,
      // autoWidth: true,
      // bookType: 'xlsx',
      // 甲方单位
      firstCompanys: [],
      firstValue: [], //选中甲方
      // 维度
      type: [
        {
          value: '1',
          label: '项目'
        },
        {
          value: '2',
          label: '安装单位'
        },
        {
          value: '3',
          label: '制造单位'
        },
        {
          value: '4',
          label: '设备类型'
        }
      ],
      typeValue: '4', //选中维度
      // 筛选模型
      params: {
        uname: '',
        date: [],
        pagenum: 1
      },
      // 表格列
      columns: [
        { title: '名称', field: 'name' },
        { title: '得分', field: 'score' }
      ],
      // 表格数据
      tableData: []
    }
  },
  methods: {
    // 默认时间
    defaultTime() {
      if (this.pickerDate.length == 0) {
        // 默认当前日期之前365天
        let sdate = new Date()
        let interval = -365 //间隔天数
        let caret = '-' //日期分隔符
        let strYear = sdate.getFullYear()
        let strDay = sdate.getDate()
        let strMonth = sdate.getMonth() + 1
        let patt1 = /^\d{4}-([0-1]?[0-9])-([0-3]?[0-9])$/ //判断输入的日期是否符合格式正则表达式
        if (!(sdate && typeof sdate == 'string' && patt1.test(sdate))) {
          sdate = new Date() //不满足日期的则使用当前年月日
        }
        interval = isNaN(parseInt(interval)) ? 0 : parseInt(interval) //若没有输入间隔，则使用当前日
        caret = caret && typeof caret == 'string' ? caret : ''
        let gdate = new Date(sdate).getTime() //获取指定年月日
        gdate = gdate + 1000 * 60 * 60 * 24 * interval //加减相差毫秒数
        let speDate = new Date(gdate) //获取指定好毫秒数时间
        let preYear = speDate.getFullYear()
        let preMonth = speDate.getMonth() + 1
        let preDay = speDate.getDate()
        preMonth = preMonth < 10 ? '0' + preMonth : preMonth
        preDay = preDay < 10 ? '0' + preDay : preDay
        let preDate = preYear + caret + preMonth + caret + preDay
        this.pickerDate = [preDate, strYear + '-' + strMonth + '-' + strDay]
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },
    //选择日期事件
    handleDateChange(pickerDate) {
      if (pickerDate == null) {
        this.pickerDate = []
      }
      this.queryScore()
    },
    //甲方下拉框
    changePartyName(firstValue) {
      console.log(firstValue)
      this.firstValue = firstValue
    },
    //甲方单位
    queryPartyaNames() {
      queryPartyaNames().then((res) => {
        this.firstCompanys = res.data
      })
    },
    //维度下拉选择
    changeType(typeValue) {
      console.log(typeValue)
      this.typeValue = typeValue
    },
    // 点击搜索
    search() {
      if (this.typeValue == '' || this.pickerDate.length == 0) {
        this.$message({
          message: '请选择搜索条件',
          type: 'warning'
        })
      } else {
        //数据请求
        // 得分统计
        this.queryScore(this.typeValue)
      }
    },
    // 多个维度统计得分
    queryScore(dimension) {
      var data = {
        dimension, //设备类型
        partyaName: this.firstValue,
        order: 'desc', //降序
        startDt: this.pickerDate[0] || '',
        endDt: this.pickerDate[1] || ''
      }
      queryScore(data).then((res) => {
        console.log(res.data)
        if (res.data.length == 0) {
          this.tableData = []
          this.$message({
            message: '暂无数据'
          })
        } else {
          this.tableData = res.data
        }

        console.log('得分结果')
      })
    },
    download() {
      console.log(this.tableData)
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then((excel) => {
        /**
         * 配置表头 和对应的 key
         */
        const tHeader = ['名称', '得分', '图片']
        const filterVal = ['name', 'score', 'image']
        const list = this.tableData // 数据源
        const data = this.formatJson(filterVal, list)
        console.log(list, 'list')
        console.log(data, 'da')
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '检查得分统计'
          // autoWidth: this.autoWidth,
          // bookType: this.bookType
        })
        this.downloadLoading = false
      })
    },
    // 格式转换
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    }
  },

  created() {
    this.defaultTime()
    this.queryPartyaNames()
    // this.queryScore(this.typeValue)
    console.log(this.tableData)
  },
  mounted() {
    this.queryScore(this.typeValue)
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-select__tags {
  flex-wrap: nowrap;
  overflow: auto;
}

/* 输入框最大宽度*/
::v-deep .el-select__tags-text {
  max-width: 90px;
}

/* 底部滚动条的高度*/
::v-deep .el-select__tags::-webkit-scrollbar {
  height: 4px !important;
}
</style>
