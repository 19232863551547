<template>
  <div class="create">
    <!-- card -->
    <el-card class="box-card">
      <!-- card header -->
      <div slot="header" class="clearfix">
        <span>员工福利信息创建</span>
        <el-button
          class="tz-r tz-create-btn"
          type="primary"
          round
          @click="jump('/empwelfare')"
          >返回</el-button
        >
      </div>
      <!-- card header -->

      <!-- card content -->
      <tz-form
        width="110px"
        :formConfig="formConfig"
        :formBtns="formBtns"
        @submit="submitFn"
      ></tz-form>
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import { saveWelfare } from '@/api/welfare'

export default {
  data() {
    return {
      // 表单配置
      formConfig: [
        {
          label: '月份',
          field: 'date',
          type: 'datePicker',
          format: 'yyyy-MM',
          placeholder: '请选择月份',
          pickerType: 'month',
          rules: [{ required: true, message: '月份必选', trigger: 'blur' }]
        },
        {
          label: '高温补贴',
          field: 'highTemperaturePay',
          type: 'text',
          rules: [{ required: true, message: '高温补贴必填', trigger: 'blur' }]
        },
        {
          label: '过节费',
          field: 'festivitiesPay',
          type: 'text',
          rules: [{ required: true, message: '过节费必填', trigger: 'blur' }]
        }
      ],
      formBtns: [
        {
          content: '创建',
          width: '185px',
          type: 'primary'
        },
        {
          content: '重置',
          width: '185px',
          type: ''
        }
      ]
    }
  },
  methods: {
    submitFn(formData) {
      console.log(formData, '单位创建')
      let infosArr = []

      if (this.submited) return
      this.$loading({
        text: '单位信息添加中'
      })
      let infos = JSON.parse(JSON.stringify(formData))
      infos.date.forEach((el) => {
        let infoObj = {}
        infoObj.date = el
        infoObj.highTemperaturePay = infos.highTemperaturePay
        infoObj.festivitiesPay = infos.festivitiesPay
        infoObj.otherPay = infos.otherPay
        infosArr.push(infoObj)
      })
      saveWelfare(infosArr).then((res) => {
        console.log(res.data, '创建')
        if (res.code == 200) {
          this.submited = true
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.$router.go(-1)
        } else {
          this.$message({
            message: res.data,
            type: 'error'
          })
        }
      })
      this.$loading().close()
    }
  }
}
</script>

<style></style>
