<template>
  <div class="history">
    <!-- card -->
    <el-card class="box-card">
      <!-- card header -->
      <div slot="header" class="clearfix">
        <span>{{ unitInstall }}工程明细</span>
        <el-button
          class="tz-r tz-create-btn"
          type="primary"
          round
          @click="jump('/file/inspectUnit')"
          >返回</el-button
        >
      </div>

      <!-- 表格 -->
      <TzTable :tableData="tableData" :columns="columns" />
      <!-- 表格 -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import TzTable from '@/components/tz-table/Index.vue'
import { queryInspectUnitCountInfo } from '@/api/inspect'
export default {
  components: {
    TzTable
  },
  data() {
    return {
      unitInstall: '', //安装单位
      partyaName: [], //甲方单位
      unit: [], //租赁单位
      projectName: [], //工地
      startDt: '', //开始时间
      endDt: '', //结束时间
      // 筛选模型
      params: {
        uname: '',
        date: [],
        pagenum: 1
      },
      // 表格列
      columns: [
        { title: '序号', field: 'id', width: 100 },
        { title: '项目名称', field: 'projectName' },
        { title: '安全分值', field: 'aveScore' },
        { title: '平均生产日期', field: 'factoryOutdate' },
        { title: '检查数量', field: 'count' },
        { title: '危险设备（<60分）', field: 'dangerous' }
      ],
      // 表格数据
      tableData: []
    }
  },
  created() {
    this.setups()
  },
  methods: {
    setups() {
      console.log(this.$route.query)
      let dataId = this.$route.query
      ;(this.unitInstall = dataId.unitInstall),
        (this.partyaName = dataId.partyaName),
        (this.unit = dataId.unit),
        (this.projectName = dataId.projectName),
        (this.startDt = dataId.startDt || ''),
        (this.endDt = dataId.endDt || '')
    },
    // 数据统计
    queryInspectUnitCountFn() {
      var data = {
        name: this.unitInstall,
        partyaName: this.partyaName,
        unit: this.unit,
        projectName: this.projectName,
        startDt: this.startDt || '',
        endDt: this.endDt || ''
      }
      queryInspectUnitCountInfo(data).then((res) => {
        console.log(res.data)
        console.log('结果')
        if (res.data.length == 0) {
          this.tableData = []
          this.$message({
            message: '暂无数据'
          })
        } else {
          res.data.forEach((item, index) => {
            item['id'] = index + 1
          })
          this.tableData = res.data
        }
      })
    }
  },
  mounted() {
    this.queryInspectUnitCountFn()
  }
}
</script>

<style lang="scss" scoped></style>
