<template>
  <div class="history">
    <!-- card -->
    <el-card class="box-card">
      <div slot="header">
        <el-row style="display: flex; align-items: center">
          <el-col :span="4">
            <span>各项目检查情况</span>
          </el-col>
          <el-col :span="11"> &nbsp; </el-col>
          <!-- 时间选择 -->
          <el-col :span="8">
            <el-date-picker
              v-model="pickerDate"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :editable="false"
              unlink-panels
              :picker-options="pickerOptions"
              @change="handleDateChange"
            >
            </el-date-picker>
          </el-col>
          <!-- 下载 -->
          <el-col :span="1">
            <el-button
              v-if="tableData.length != 0"
              icon="el-icon-download"
              type="info"
              :loading="downloadLoading"
              @click="download"
            ></el-button>
          </el-col>
        </el-row>
      </div>
      <!-- card header -->

      <!-- card content -->
      <!-- 筛选 -->
      <el-row>
        <el-col :span="5">
          <!-- 甲方单位 -->
          <el-select
            size="middle"
            multiple
            clearable
            v-model="firstValue"
            placeholder="请选择甲方单位"
            @change="changePartyName"
          >
            <el-checkbox-group v-model="firstValue">
              <el-option
                v-for="item in firstCompanys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <el-checkbox
                  style="pointer-events: none"
                  :label="item.value"
                ></el-checkbox>
              </el-option>
            </el-checkbox-group>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select
            size="middle"
            multiple
            clearable
            v-model="unitValue"
            placeholder="请选择租赁单位"
            @change="changeUnit"
          >
            <el-checkbox-group v-model="unitValue">
              <el-option
                v-for="item in unitCompanys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <el-checkbox
                  style="pointer-events: none"
                  :label="item.value"
                ></el-checkbox>
              </el-option>
            </el-checkbox-group>
          </el-select>
        </el-col>
        <!-- 工地  -->
        <el-col :span="5">
          <!-- 工地-->
          <el-select
            size="middle"
            multiple
            clearable
            v-model="projectName"
            placeholder="请选择工地"
            @change="changeProject"
          >
            <el-checkbox-group v-model="projectName">
              <el-option
                v-for="item in projectNames"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <el-checkbox
                  style="pointer-events: none"
                  :label="item.value"
                ></el-checkbox>
              </el-option>
            </el-checkbox-group>
          </el-select>
        </el-col>

        <!-- 搜索 -->
        <el-col :span="1">
          <el-button
            icon="el-icon-search"
            type="primary"
            plain
            @click="search"
          ></el-button>
        </el-col>
        <!-- <el-col :span="6">&nbsp;</el-col> -->
      </el-row>
      <!-- 筛选 -->

      <!-- 表格 -->
      <TzTable :tableData="tableData" :columns="columns" />
      <!-- 表格 -->

      <!-- 分页 -->
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pagenum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination> -->
      <!-- 分页 -->
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import TzTable from '@/components/tz-table/Index.vue'
import {
  queryPartyaNames,
  queryUnit,
  queryProjectName,
  queryScoreCountByProjectName
} from '@/api/inspect'
export default {
  components: {
    TzTable
  },
  data() {
    return {
      pickerDate: [], //日期 时间戳
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },
      downloadLoading: false,
      // 检索条件
      firstValue: [],
      unitValue: [],
      projectName: [],
      projectNames: [],
      unitCompanys: [],
      firstCompanys: [],
      // 筛选模型
      params: {
        uname: '',
        date: [],
        pagenum: 1
      },
      // 表格列
      columns: [
        { title: '序号', field: 'id', width: 100 },
        { title: '工程名称', field: 'name', width: 300 },
        { title: '评分', field: 'aveScore' },
        { title: '生产日期', field: 'beginTime' },
        { title: '设备数量', field: 'count' },
        { title: '危险设备数', field: 'dangerous' }
      ],
      // 表格数据
      tableData: []
    }
  },
  methods: {
    // 默认时间
    defaultTime() {
      if (this.pickerDate.length == 0) {
        // 默认当前日期之前365天
        let sdate = new Date()
        let interval = -365 //间隔天数
        let caret = '-' //日期分隔符
        let strYear = sdate.getFullYear()
        let strDay = sdate.getDate()
        let strMonth = sdate.getMonth() + 1
        let patt1 = /^\d{4}-([0-1]?[0-9])-([0-3]?[0-9])$/ //判断输入的日期是否符合格式正则表达式
        if (!(sdate && typeof sdate == 'string' && patt1.test(sdate))) {
          sdate = new Date() //不满足日期的则使用当前年月日
        }
        interval = isNaN(parseInt(interval)) ? 0 : parseInt(interval) //若没有输入间隔，则使用当前日
        caret = caret && typeof caret == 'string' ? caret : ''
        let gdate = new Date(sdate).getTime() //获取指定年月日
        gdate = gdate + 1000 * 60 * 60 * 24 * interval //加减相差毫秒数
        let speDate = new Date(gdate) //获取指定好毫秒数时间
        let preYear = speDate.getFullYear()
        let preMonth = speDate.getMonth() + 1
        let preDay = speDate.getDate()
        preMonth = preMonth < 10 ? '0' + preMonth : preMonth
        preDay = preDay < 10 ? '0' + preDay : preDay
        let preDate = preYear + caret + preMonth + caret + preDay
        this.pickerDate = [preDate, strYear + '-' + strMonth + '-' + strDay]
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },
    //选择日期事件
    handleDateChange(pickerDate) {
      if (pickerDate == null) {
        this.pickerDate = []
      }
      this.queryScoreCountByProjectNameFn()
    },
    //甲方下拉框
    changePartyName(firstValue) {
      // console.log(firstValue)
      this.firstValue = firstValue
      this.unitValue = []
      this.projectName = []
      this.queryUnit(), //租赁单位
        this.queryProjectName() //工地
    },

    //租赁下拉框
    changeUnit(unitValue) {
      // console.log(unitValue)
      this.unitValue = unitValue
      this.projectName = []
      this.queryProjectName() //工地
    },
    //工地下拉框
    changeProject(projectName) {
      this.projectName = projectName
      // this.queryUnit() //租赁单位
    },
    //甲方单位
    queryPartyaNames() {
      queryPartyaNames().then((res) => {
        console.log(res.data, '甲方单位')
        this.firstCompanys = res.data
      })
    },

    //租赁单位
    queryUnit() {
      var data = {
        partyaName: this.firstValue,
        unit: this.unitValue,
        projectName: this.projectName
      }
      queryUnit(data).then((res) => {
        console.log(res.data, '租赁单位')
        this.unitCompanys = res.data
        // this.queryProjectName() //工地
      })
    },
    //工地
    queryProjectName() {
      var data = {
        partyaName: this.firstValue,
        unit: this.unitValue,
        projectName: this.projectName
      }
      queryProjectName(data).then((res) => {
        console.log(res.data, '工地')
        this.projectNames = res.data
        // this.queryUnit() //租赁单位
      })
    },
    // 点击搜索
    search() {
      // if (this.pickerDate == null || this.pickerDate.length == 0) {
      //   console.log('请选择')
      //   this.$message({
      //     message: '请选择搜索条件',
      //     type: 'warning'
      //   })
      // } else {
      //数据请求
      this.queryScoreCountByProjectNameFn()
      // }
    },
    // 数据统计
    queryScoreCountByProjectNameFn() {
      var data = {
        partyaName: this.firstValue,
        unit: this.unitValue,
        projectName: this.projectName,
        // order: 'desc', //降序
        startDt: this.pickerDate[0] || '',
        endDt: this.pickerDate[1] || ''
      }
      queryScoreCountByProjectName(data).then((res) => {
        console.log(res.data)
        console.log('结果')
        if (res.data.length == 0) {
          this.tableData = []
          this.$message({
            message: '暂无数据'
          })
        } else {
          res.data.forEach((item, index) => {
            item['id'] = index + 1
          })
          this.tableData = res.data
        }
      })
    },
    download() {
      console.log(this.tableData)
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then((excel) => {
        /**
         * 配置表头 和对应的 key
         */
        const tHeader = [
          '序号',
          '工程名称',
          '评分',
          '生产日期',
          '设备数量',
          '危险设备数'
        ]
        const filterVal = [
          'id',
          'name',
          'aveScore',
          'beginTime',
          'count',
          'dangerous'
        ]
        const list = this.tableData // 数据源
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '各项目检查情况'
          // autoWidth: this.autoWidth,
          // bookType: this.bookType
        })
        this.downloadLoading = false
      })
    },
    // 格式转换
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    }
  },
  created() {
    this.defaultTime()

    this.queryPartyaNames() //甲方单位
    this.queryUnit(), //租赁单位
      this.queryProjectName(), //工地
      console.log(this.tableData)
  },
  mounted() {
    this.queryScoreCountByProjectNameFn()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-select__tags {
  flex-wrap: nowrap;
  overflow: auto;
}

/* 输入框最大宽度*/
::v-deep .el-select__tags-text {
  max-width: 90px;
}

/* 底部滚动条的高度*/
::v-deep .el-select__tags::-webkit-scrollbar {
  height: 4px !important;
}
</style>
