<template>
  <div class="history">
    <!-- card -->
    <el-card class="box-card">
      <div slot="header">
        <el-row style="display: flex; align-items: center">
          <el-col :span="4">
            <span>检测（报告）合格率</span>
          </el-col>
          <el-col :span="11"> &nbsp; </el-col>
          <!-- 时间选择 -->
          <el-col :span="8">
            <el-date-picker
              v-model="pickerDate"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :editable="false"
              unlink-panels
              :picker-options="pickerOptions"
              @change="handleDateChange"
            >
            </el-date-picker>
          </el-col>
          <!-- 下载 -->
          <el-col :span="1">
            <el-button
              v-if="tableData.length != 0"
              icon="el-icon-download"
              type="info"
              @click="download"
            ></el-button>
          </el-col>
        </el-row>
      </div>
      <!-- card header -->

      <!-- card content -->
      <!-- 筛选 -->
      <el-row>
        <el-col :span="4">
          <el-switch
            style="display: inline-block"
            v-model="switchValue"
            active-color="#50A9FF"
            inactive-color="#50A9FF"
            active-text="其他"
            inactive-text="区域"
            active-value="other"
            inactive-value="area"
            @change="changeSwitch"
          >
          </el-switch>
        </el-col>
        <!-- 维度下拉 -->
        <el-col :span="4" v-if="switchValue == 'other'">
          <el-select
            class="select"
            v-model="typeValue"
            placeholder="请选择搜索条件"
            @change="changeType"
          >
            <el-option
              v-for="item in type"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <!-- 区域查询 -->
        <el-col :span="4" v-if="switchValue == 'area'">
          <el-select
            class="select"
            v-model="areaValue"
            placeholder="请选择区域"
            @change="changeAreaType"
          >
            <el-option
              v-for="item in areaList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>

        <!-- 搜索 -->
        <el-col :span="1">
          <el-button
            icon="el-icon-search"
            type="primary"
            plain
            @click="search"
          ></el-button>
        </el-col>
        <el-col :span="11">&nbsp;</el-col>
      </el-row>
      <!-- 筛选 -->

      <!-- 表格 -->
      <TzTable
        :tableData="tableData"
        :columns="switchValue == 'area' ? areaColumns : otherColumns"
      />
      <!-- 表格 -->

      <!-- 分页 -->
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pagenum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination> -->
      <!-- 分页 -->
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import TzTable from '@/components/tz-table/Index.vue'
import {
  queryTestReportInfo,
  queryArea,
  queryTestReportByAreaInfo
} from '@/api/detection'
export default {
  components: {
    TzTable
  },
  data() {
    return {
      downloadLoading: false,
      switchValue: 'area',
      pickerDate: [], //日期 时间戳
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },
      areaValue: '', ///选中区域
      areaList: [],
      // 维度
      type: [
        {
          value: '1',
          label: '设备类型'
        },
        {
          value: '2',
          label: '安装单位'
        }
      ],
      typeValue: '', //选中维度
      // 筛选模型
      params: {
        uname: '',
        date: [],
        pagenum: 1
      },
      // 表格列
      otherColumns: [
        { title: '名称', field: 'name' },
        { title: '初检(%)', field: 'firstQualifiedRate' },
        { title: '复检(%)', field: 'secondQualifiedRate' },
        { title: '定检(%)', field: 'regularQualifiedRate' }
      ],
      areaColumns: [
        { title: '区域', field: 'name' },
        { title: '合格率(%)', field: 'count' }
      ],
      // 表格数据
      tableData: [],
      // 下载
      tHeader: [],
      filterVal: []
    }
  },
  methods: {
    // 默认时间
    defaultTime() {
      if (this.pickerDate.length == 0) {
        // 默认当前日期之前365天
        let sdate = new Date()
        let interval = -365 //间隔天数
        let caret = '-' //日期分隔符
        let strYear = sdate.getFullYear()
        let strDay = sdate.getDate()
        let strMonth = sdate.getMonth() + 1
        let patt1 = /^\d{4}-([0-1]?[0-9])-([0-3]?[0-9])$/ //判断输入的日期是否符合格式正则表达式
        if (!(sdate && typeof sdate == 'string' && patt1.test(sdate))) {
          sdate = new Date() //不满足日期的则使用当前年月日
        }
        interval = isNaN(parseInt(interval)) ? 0 : parseInt(interval) //若没有输入间隔，则使用当前日
        caret = caret && typeof caret == 'string' ? caret : ''
        let gdate = new Date(sdate).getTime() //获取指定年月日
        gdate = gdate + 1000 * 60 * 60 * 24 * interval //加减相差毫秒数
        let speDate = new Date(gdate) //获取指定好毫秒数时间
        let preYear = speDate.getFullYear()
        let preMonth = speDate.getMonth() + 1
        let preDay = speDate.getDate()
        preMonth = preMonth < 10 ? '0' + preMonth : preMonth
        preDay = preDay < 10 ? '0' + preDay : preDay
        let preDate = preYear + caret + preMonth + caret + preDay
        this.pickerDate = [preDate, strYear + '-' + strMonth + '-' + strDay]
      }
    },
    changeSwitch() {
      if (this.switchValue == 'area') {
        console.log(this.switchValue)
        this.tableData = []
      } else if (this.switchValue == 'other') {
        console.log(this.switchValue)
        this.tableData = []
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },
    //选择日期事件
    handleDateChange(pickerDate) {
      if (pickerDate == null) {
        this.pickerDate = []
      }
      if (this.switchValue == 'other') {
        this.queryTestReportInfo()
      } else if (this.switchValue == 'area') {
        this.queryTestReportByAreaInfo()
      }
    },

    //维度下拉选择
    changeType(typeValue) {
      console.log(typeValue)
      this.typeValue = typeValue
    },
    //区域下拉
    changeAreaType(areaValue) {
      console.log(areaValue)
      this.areaValue = areaValue
    },
    // 点击搜索
    search() {
      console.log(this.pickerDate, this.typeValue, this.areaValue)
      //数据请求
      console.log(this.switchValue)
      if (this.switchValue == 'other') {
        if (this.typeValue == '' || this.pickerDate.length == 0) {
          console.log('请选择')
          this.$message({
            message: '请选择搜索条件',
            type: 'warning'
          })
        } else {
          this.queryTestReportInfo()
        }
      } else if (this.switchValue == 'area') {
        if (this.areaValue == '' || this.pickerDate.length == 0) {
          console.log('请选择')
          this.$message({
            message: '请选择搜索条件',
            type: 'warning'
          })
        } else {
          this.queryTestReportByAreaInfo()
        }
      }
    },

    // 获取区域
    queryArea() {
      let getQueryAreaData = {
        region: ''
      }
      queryArea(getQueryAreaData).then((res) => {
        this.areaList = res.data.map((itm) => {
          return { label: itm.region, value: itm.city }
        })
      })
    },
    //维度数据查询
    queryTestReportInfo() {
      var data = {
        dimension: this.typeValue, //维度
        order: 'desc', //降序
        isBasket: '1', //包含吊篮
        startDt: this.pickerDate[0] || '',
        endDt: this.pickerDate[1] || ''
      }
      queryTestReportInfo(data).then((res) => {
        console.log(res.data)
        console.log('报告，维度')
        if (res.data.length == 0) {
          this.tableData = []
          this.$message({
            message: '暂无数据'
          })
        } else {
          this.tableData = res.data
        }
      })
    },
    //区域查询数据
    queryTestReportByAreaInfo() {
      var data = {
        order: 'desc', //降序
        startDt: this.pickerDate[0] || '',
        endDt: this.pickerDate[1] || '',
        city: this.areaValue
      }
      queryTestReportByAreaInfo(data).then((res) => {
        console.log(res.data)
        console.log('报告，区域')
        if (res.data.length == 0) {
          this.tableData = []
          this.$message({
            message: '暂无数据'
          })
        } else {
          this.tableData = res.data
        }
      })
    },

    download() {
      console.log(this.switchValue)

      console.log(this.tableData)
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then((excel) => {
        /**
         * 配置表头 和对应的 key
         */
        const filename = '检测(报告)合格率'

        const list = this.tableData // 数据源
        if (this.switchValue == 'area') {
          this.tHeader = ['区域', '合格率(%)']
          this.filterVal = ['name', 'count']
          excel.export_json_to_excel({
            header: this.tHeader,
            data: this.formatJson(this.filterVal, list),
            filename
          })
        } else if (this.switchValue == 'other') {
          this.tHeader = ['名称', '初检(%)', '复检(%)', '定检(%)']
          this.filterVal = [
            'name',
            'firstQualifiedRate',
            'secondQualifiedRate',
            'regularQualifiedRate'
          ]
          excel.export_json_to_excel({
            header: this.tHeader,
            data: this.formatJson(this.filterVal, list),
            filename
          })
        }
        this.downloadLoading = false
      })
    },
    // 格式转换
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    }
  },
  created() {
    console.log(this.switchValue)
    if (this.switchValue == 'area') {
      this.queryArea()
    }
    this.defaultTime()
  },
  mounted() {
    if (this.switchValue == 'other') {
      this.queryTestReportInfo()
    } else if (this.switchValue == 'area') {
      this.queryTestReportByAreaInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.clearfix {
  display: flex;
  justify-content: space-between;
}
</style>
