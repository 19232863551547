<template>
  <div class="history">
    <!-- card -->
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ personName }}工作时长明细</span>
        <el-button
          class="tz-r tz-create-btn"
          type="primary"
          round
          @click="jump('/workinghours')"
          >返回</el-button
        >
      </div>

      <!-- 筛选 -->
      <el-row>
        <el-col :span="3">
          <div style="height: 40px; line-height: 40px">
            <el-radio v-model="type" label="1" @change="changeRadio"
              >检测</el-radio
            >
            <el-radio v-model="type" label="2" @change="changeRadio"
              >检查</el-radio
            >
          </div>
        </el-col>
        <!-- 时间选择 -->
        <el-col :span="7">
          <el-date-picker
            v-model="pickerDate"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :editable="false"
            unlink-panels
            :picker-options="pickerOptions"
            @change="handleDateChange"
          >
          </el-date-picker>
        </el-col>
        <!-- <el-col :span="5">
          <el-input
            placeholder="请输入姓名"
            v-model="personName"
            clearable
            class="input-with-select"
          > -->
        <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="userSearch"
            ></el-button> -->
        <!-- </el-input>
        </el-col> -->
        <!-- 搜索 -->
        <!-- <el-col :span="1">
          <el-button
            icon="el-icon-search"
            type="primary"
            plain
            @click="search"
          ></el-button>
        </el-col> -->
        <!-- <el-col :span="6"> -->
        <!-- <el-input
            placeholder="请输入登录用户名"
            v-model="params.uname"
            class="input-with-select"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input> -->
        <!-- </el-col> -->

        <!-- <el-col :span="12">&nbsp;</el-col> -->
        <!-- 下载 -->
        <!-- <el-col :span="1">
          <el-button
            v-if="tableData.length != 0"
            icon="el-icon-download"
            type="info"
            plain
            :loading="downloadLoading"
            @click="download"
          ></el-button>
        </el-col> -->
      </el-row>
      <!-- 筛选 -->

      <!-- 表格 -->
      <TzTable :tableData="tableData" :columns="columns" />
      <!-- <el-table :data="tableData" border style="width: 100%" ref="table">
        <el-table-column
          v-for="(item, i) in column"
          :key="i"
          :prop="item.prop"
          :label="item.label"
          sortable
        >
        </el-table-column>
        <el-table-column
          v-for="(item, i) in columns"
          :key="i + 'i'"
          :prop="item.prop"
          :label="item.label"
          sortable
        >
        </el-table-column>
      </el-table> -->
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
// import tableData from '~mock/users/history'
// import fileDownload from 'js-file-download'
import TzTable from '@/components/tz-table/Index.vue'
import { pageListInfo } from '@/api/workinghours'
export default {
  components: {
    TzTable
  },

  data() {
    return {
      downloadLoading: false,
      type: '1', //1检测，2检查
      personName: '',
      personId: '',
      offset: '', //当前起始位置（page-1）*limit
      page: 1, //当前页
      limit: '10', //
      total: 100,
      pickerDate: [], //日期 默认上个月
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },

      // 表格动态列
      columns: [
        {
          field: 'time',
          title: '日期',
          width: '300px'
        },
        {
          field: 'worktype',
          title: '工作类型',
          width: '300px'
        },
        {
          field: 'workingHours',
          title: '工作时长'
        }
      ],
      // 表格数据
      tableData: [],
      equipmentForm: {
        // name: '',
        // region: '',
        // date1: '',
        // date2: ''
      },
      formLabelWidth: '150px'
    }
  },
  created() {
    this.setups()
  },
  methods: {
    setups() {
      console.log(this.$route.query)
      let dataId = this.$route.query

      this.personName = dataId.personName
      this.personId = dataId.personId
      this.pickerDate[0] = dataId.startDt || ''
      this.pickerDate[1] = dataId.endDt || ''
    },
    // 获取上个月第一天
    // getLastMonthFirstDay() {
    //   let date = new Date()
    //   date.setDate(0)
    //   var y = date.getFullYear() //获取年份
    //   var m = date.getMonth() + 1 //获取月份
    //   m = m < 10 ? '0' + m : m //月份补 0
    //   return [y, m, '01'].join('-')
    // },
    // 获取上个月最后一天
    // getLastMonthLastDay() {
    //   let date = new Date()
    //   date.setDate(0)
    //   var y = date.getFullYear() //获取年份
    //   var m = date.getMonth() + 1 //获取月份
    //   var d = new Date(y, m, 0).getDate() //获取当月最后一日
    //   m = m < 10 ? '0' + m : m //月份补 0
    //   d = d < 10 ? '0' + d : d //日数补 0
    //   return [y, m, d].join('-')
    // },

    handleSizeChange(val) {
      this.limit = val
      this.pageListInfoFn()
    },
    handleCurrentChange(val) {
      this.page = val
      this.pageListInfoFn()
    },
    //选择日期事件
    handleDateChange(dialogPickerDate) {
      console.log(dialogPickerDate)
      if (dialogPickerDate == null) {
        this.pickerDate = []
      }
      this.page = 1
      this.pageListInfoFn()
    },
    changeRadio() {
      this.page = 1
      this.pageListInfoFn()
    },
    //获取数据
    pageListInfoFn() {
      var data = {
        type: this.type,
        personId: this.personId,
        startDt: this.pickerDate[0] || '',
        endDt: this.pickerDate[1] || '',
        pageNum: this.page,
        pageSize: this.limit
      }
      // console.log(this.pickerDate, 'fdsfasdf')
      pageListInfo(data).then((res) => {
        console.log(res.data, '工作量')
        if (res.code == 200) {
          let dictArr = res.data.records
          this.total = res.data.total
          dictArr.forEach((el) => {
            el.worktype = el.type == 1 ? '检测' : el.type == 2 ? '检查' : '未知'
          })
          this.tableData = dictArr
        }
      })
    },
    // 点击搜索
    search() {
      this.pageListInfoFn()
    }
    //下载
    // download() {
    //   this.downloadLoading = true
    //   var data = {
    //     startDt: this.pickerDate[0] || '',
    //     endDt: this.pickerDate[1] || '',
    //     type: this.type
    //   }
    //   workloadExport(data).then((res) => {
    //     fileDownload(res, '工作量统计.xlsx')
    //   })
    //   this.downloadLoading = false
    // }
  },
  mounted() {
    this.pageListInfoFn()
  }
}
</script>

<style lang="scss" scoped>
.clearfix {
  display: flex;
  justify-content: space-between;
}
</style>
