<template>
  <div class="history">
    <!-- card -->
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>车辆设置</span>
        <div>
          <el-button
            class="tz-r tz-create-btn"
            type="primary"
            round
            @click="add"
            >添加设置</el-button
          >
          <el-button
            class="tz-r tz-create-btn"
            type="primary"
            round
            @click="jump('/carstatics')"
            >返回</el-button
          >
        </div>
      </div>
      <!-- 筛选 -->
      <el-row>
        <!-- 下拉 -->
        <el-col :span="5">
          <el-select
            class="select"
            clearable
            filterable
            v-model="userName"
            placeholder="请选择驾驶员"
            @clear="clearDriver"
            @change="changeDriver"
          >
            <el-option
              v-for="item in driverList"
              :key="item.personId"
              :label="item.personName"
              :value="item.personId"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select
            class="select"
            clearable
            v-model="carTypeValue"
            placeholder="请选择车辆"
            @change="changeCarType"
            @clear="clearCarType"
          >
            <el-option
              v-for="item in carList"
              :key="item.carPlate"
              :label="item.carPlate"
              :value="item.carPlate"
            >
            </el-option>
          </el-select>
        </el-col>
        <!-- 搜索 -->
        <!-- <el-col :span="1">
          <el-button
            icon="el-icon-search"
            type="primary"
            plain
            @click="pageList"
          ></el-button>
        </el-col> -->
        <el-col :span="13">&nbsp;</el-col>
      </el-row>

      <!-- 表格 -->
      <el-table :data="tableData" border style="width: 100%" ref="table">
        <el-table-column
          v-for="(item, i) in column"
          :key="i"
          :prop="item.prop"
          :label="item.label"
          sortable
        >
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              @click="handleEditClick(scope.row)"
              type="primary"
              size="small"
              >编辑</el-button
            >
            &nbsp;
            <el-popconfirm
              title="确认删除该车辆设置吗？"
              @confirm="deleteCarSet(scope.row)"
            >
              <el-button slot="reference" type="danger" size="small"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 弹框 -->
      <el-dialog
        title="车辆设置"
        width="40%"
        center
        :visible.sync="dialogFormVisible"
        destroy-on-close
      >
        <el-form
          style="width: 80%"
          :model="equipmentForm"
          :ref="equipmentForm"
          label-width="120px"
          inline-message
        >
          <el-form-item label="车牌号:">
            <el-select
              class="select"
              :disabled="!isadd"
              filterable
              v-model="equipmentForm.carNum"
              placeholder="请选择车辆"
              @change="changeDialogCarNum"
            >
              <el-option
                v-for="item in carList"
                :key="item.carPlate"
                :label="item.carPlate"
                :value="item.carPlate"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="驾驶员:">
            <el-select
              placeholder="请选择驾驶员"
              style="width: 100%"
              filterable
              class="select"
              v-model="equipmentForm.userName"
              @change="changedialogDriver"
            >
              <el-option
                v-for="item in driverList"
                :key="item.personId"
                :label="item.personName"
                :value="item.personId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="isadd ? addSet() : updateSet()"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import {
  pageListCars,
  pageListDrivers,
  pageList,
  addCarSet,
  updateCarSet,
  deleteCarSet
} from '@/api/aboutCar'

export default {
  components: {},

  data() {
    return {
      dialogFormVisible: false,
      offset: '', //当前起始位置（page-1）*limit
      page: 1, //当前页
      limit: '10', //
      total: 0,

      // 表格固定列
      column: [
        {
          prop: 'carNum',
          label: '车牌号'
        },
        {
          prop: 'userName',
          label: '驾驶员'
        }
      ],

      // 表格数据
      tableData: [],
      equipmentForm: {
        carNum: '',
        userId: '',
        userName: ''
      },
      isadd: false, //弹窗ture为添加
      formLabelWidth: '150px',

      carList: [],
      carTypeValue: '',
      driverList: [],
      userName: '',
      userId: '',
      dialogId: ''
    }
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.pageList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.pageList()
    },
    //获取车辆列表
    getListCars() {
      pageListCars().then((res) => {
        console.log(res.data, '车辆列表')
        this.carList = res.data
      })
    },
    //获取驾驶员列表
    getListDrivers() {
      pageListDrivers({
        personName: this.personName
      }).then((res) => {
        console.log(res.data, '驾驶员列表')
        this.driverList = res.data
      })
    },

    //驾驶员下拉
    changeDriver(value) {
      this.userId = value
      this.userName = this.driverList.find(
        (item) => item.personId == value
      ).personName
      // console.log(this.userId, this.userName, '驾驶员')
      this.page = 1
      this.pageList()
    },
    clearDriver() {
      this.userId = ''
      this.userName = ''
      this.page = 1
      this.pageList()
    },
    //车辆下拉
    changeCarType(value) {
      this.carTypeValue = value
      // console.log(this.carTypeValue, '车辆')
      this.page = 1
      this.pageList()
    },
    clearCarType() {
      this.carTypeValue = ''
      this.page = 1
      this.pageList()
    },

    //弹窗驾驶员下拉
    changedialogDriver(value) {
      this.equipmentForm.userId = value
      this.equipmentForm.userName = this.driverList.find(
        (item) => item.personId == value
      ).personName
    },
    // 弹窗车辆下拉
    changeDialogCarNum(value) {
      this.equipmentForm.carNum = value
    },
    //获取数据
    pageList() {
      var data = {
        pageNum: this.page,
        pageSize: this.limit,
        carNum: this.carTypeValue,
        userId: this.userId
      }
      console.log(data)
      pageList(data).then((res) => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },

    // // 点击搜索
    // search() {
    //   console.log(this.monthValue)
    //   this.pageList()
    // },
    // 显示弹窗编辑信息
    handleEditClick(row) {
      this.isadd = false
      // console.log(row, 'row')
      this.dialogFormVisible = true
      this.equipmentForm.carNum = row.carNum
      this.equipmentForm.userId = row.userId
      this.equipmentForm.userName = row.userName
      this.dialogId = row.id
    },

    //弹窗 修改 确定
    updateSet() {
      let data = {
        carNum: this.equipmentForm.carNum,
        userId: this.equipmentForm.userId,
        userName: this.equipmentForm.userName,
        id: this.dialogId
      }
      updateCarSet(data).then((res) => {
        console.log(res)
        if (res.code == '200' && res.msg == '处理成功') {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 4000
          })
          setTimeout(() => {
            this.dialogFormVisible = false
          }, 500)
          this.pageList()
        } else if (res.code == '402') {
          this.$message({
            message: res.data,
            duration: 4000
          })
        } else {
          this.$message({
            message: '修改失败',
            duration: 4000
          })
        }
      })
    },
    //添加弹窗
    add() {
      this.closeDialog()
      this.isadd = true
      this.dialogFormVisible = true
    },
    addSet() {
      if (this.equipmentForm.carNum == '') {
        alert('车牌号不能为空')
      } else if (this.equipmentForm.userName == '') {
        alert('驾驶员不能为空')
      } else {
        addCarSet(this.equipmentForm).then((res) => {
          console.log(res)
          if (res.code == '200' && res.msg == '处理成功') {
            this.$message({
              message: '添加成功',
              type: 'success',
              duration: 4000
            })
            setTimeout(() => {
              this.dialogFormVisible = false
            }, 500)
            this.pageList()
          } else if (res.code == '402') {
            this.$message({
              message: res.data,
              duration: 4000
            })
          } else {
            this.$message({
              message: '添加失败',
              duration: 4000
            })
          }
        })
      }
    },

    //删除
    deleteCarSet(row) {
      deleteCarSet({ id: row.id }).then((res) => {
        console.log(res)
        if (res.code == '200' && res.msg == '处理成功') {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 4000
          })
          this.pageList()
        }
      })
    },
    //关闭弹窗
    closeDialog() {
      ;(this.dialogFormVisible = false),
        (this.equipmentForm.carNum = ''), //车牌号
        (this.equipmentForm.userId = ''), //驾驶员id
        (this.equipmentForm.userName = '')
    }
  },
  created() {
    this.getListCars()
    this.getListDrivers()
    // this.pageList()
  },
  mounted() {
    this.pageList()
  }
}
</script>

<style lang="scss" scoped>
.clearfix {
  display: flex;
  justify-content: space-between;
}
</style>
