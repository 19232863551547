<template>
  <div style="width: 100%; height: 100%" ref="mychart"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'ChartsNightingale',
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'pie'
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    data() {
      this.initMyChart() // 重置图表
    }
  },
  mounted() {
    // this.initMyChart()
    setTimeout(() => {
      this.initMyChart()
      const resizeOb = new ResizeObserver((entries) => {
        for (const entry of entries) {
          echarts.getInstanceByDom(entry.target).resize()
        }
      })
      resizeOb.observe(this.$refs.mychart)
    })
  },
  methods: {
    initMyChart() {
      const mychart = echarts.init(this.$refs.mychart)
      const option = {
        // 标题
        title: {
          text: this.title,
          left: 'center',
          top: 15,
          textStyle: {
            fontSize: 16,
            fontWeight: 'normal'
          }
        },
        legend: {
          top: 'bottom'
        },
        toolbox: {
          show: true
          // feature: {
          //   mark: { show: true },
          //   dataView: { show: true, readOnly: false },
          //   restore: { show: true },
          //   saveAsImage: { show: true }
          // }
        },
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: [40, 150],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: this.data
          }
        ]
      }
      mychart.setOption(option)
    }
  }
}
</script>
