<template>
  <div style="width: 100%; height: 100%" ref="mychart"></div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'ChartstoperrBar',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    deep: true,
    data() {
      this.initMyChart() // 重置图表
    }
  },
  data() {
    return {
      pieMyChart: null
    }
  },
  mounted() {
    // this.initMyChart()
    setTimeout(() => {
      this.initMyChart()
      const resizeOb = new ResizeObserver((entries) => {
        for (const entry of entries) {
          echarts.getInstanceByDom(entry.target).resize()
        }
      })
      resizeOb.observe(this.$refs.mychart)
    })
  },
  methods: {
    initMyChart() {
      // 基于准备好的dom，初始化echarts实例
      let mychart = echarts.getInstanceByDom(this.$refs.mychart)
      if (mychart == null) mychart = echarts.init(this.$refs.mychart)
      this.pieMyChart = mychart
      const datas = this.data
      let salvProMax = []
      let titlename = []
      let data = []
      let valdata = []
      if (datas.length > 0) {
        titlename = datas.map((item) => (item.name ? item.name : ''))
        data = datas.map((item) => item.count)
        // var salvProMax = [] //背景按最大值
        for (let i = 0; i < data.length; i++) {
          salvProMax.push(data[0])
        }
        // salvProMax = new Array(datas.length).fill(100)
        valdata = datas.map((item) => item.count)
      }

      let myColor = [
        // '#5470c6',
        // '#91cc75',
        // '#fac858',
        // '#f1daa7',
        // '#f1daa7',
        // '#f1daa7',
        // '#f1daa7',
        // '#f1daa7',
        // '#f1daa7',
        // '#f1daa7'

        '#ff0000',
        '#ffff00',
        '#00b0f0',
        '#00b050',
        '#ff0000',
        '#ffff00',
        '#00b0f0',
        '#00b050'
      ]
      let option = {
        backgroundColor: '#fff',
        title: {
          text: this.title,
          left: 'center',
          top: 15,
          textStyle: {
            fontSize: 16,
            fontWeight: 'normal'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          formatter: function (params) {
            return params[0].name + ' : ' + params[0].value
          }
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '2%',
          top: '10%',
          containLabel: true
        },
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [
          {
            show: true,
            data: titlename,
            inverse: true,
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#000',
              fontSize: 12,
              // formatter: function (value, index) {
              //   return [
              //     '{lg|' + (index + 1) + '}' + '{title|' + value + '} '
              //   ].join('\n')
              // },
              formatter: function (params) {
                var newParamsName = ''
                var paramsNameNumber = params.length
                var provideNumber = 30 //一行显示几个字
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)
                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''
                    var start = p * provideNumber
                    var end = start + provideNumber
                    if (p == rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      tempStr = params.substring(start, end) + '\n'
                    }
                    newParamsName += tempStr
                  }
                } else {
                  newParamsName = params
                }
                return newParamsName
              },
              rich: {
                lg: {
                  backgroundColor: '#339911',
                  color: '#000',
                  borderRadius: 15,
                  // padding: 5,
                  align: 'center',
                  width: 15,
                  height: 15
                }
              }
            }
          },
          {
            show: true,
            inverse: true,
            data: valdata,
            axisLabel: {
              textStyle: {
                fontSize: 14,
                color: '#000'
              }
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '占比',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              // barBorderRadius: 30,
              color: function (params) {
                var num = myColor.length
                return myColor[params.dataIndex % num]
              },
              padding: [0, 1, 2, 1]
            },
            label: {
              show: false,
              position: 'inside',
              formatter: '{c}'
            },
            barWidth: 20,
            data: data
          },
          {
            name: '背景',
            type: 'bar',
            barWidth: 20,
            barGap: '-100%',
            data: salvProMax,
            itemStyle: {
              color: 'rgb(235, 238, 245)'
              // barBorderRadius: 30
            }
          }
        ]
      }
      mychart.setOption(option, true)
    },

    // 导出单个图表图片
    Export() {
      var img = new Image()
      img.src = this.pieMyChart.getDataURL({
        type: 'png',
        pixelRatio: 1, //放大2倍
        backgroundColor: '#fff'
      })
      img.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        var ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        var dataURL = canvas.toDataURL('image/png')
        console.log(dataURL, '生成图片')

        return dataURL
        // var a = document.createElement('a')
        // var event = new MouseEvent('click')
        // a.download = '图片.png' || '下载图片名称'
        // 将生成的URL设置为a.href属性
        // a.href = dataURL
        // 触发a的单击事件
        // a.dispatchEvent(event)
        // a.remove()
      }
    }
  }
}
</script>
