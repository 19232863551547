<template>
  <div class="create">
    <!-- card -->
    <el-card class="box-card">
      <!-- card header -->
      <div slot="header" class="clearfix">
        <span>基本工资信息创建</span>
        <el-button
          class="tz-r tz-create-btn"
          type="primary"
          round
          @click="jump('/employee')"
          >返回</el-button
        >
      </div>
      <!-- card header -->

      <!-- card content -->
      <tz-form
        width="110px"
        :formConfig="formConfig"
        :formBtns="formBtns"
        @submit="submitFn"
      ></tz-form>
      <!-- card content -->
    </el-card>
    <!-- card -->
  </div>
</template>

<script>
import { memberList, addWages } from '@/api/employeesalary'

export default {
  data() {
    return {
      // 表单配置
      formConfig: [
        {
          label: '姓名',
          field: 'personId',
          type: 'select',
          payload: [],
          rules: [{ required: true, message: '姓名必填', trigger: 'blur' }]
        },
        {
          label: '身份证号',
          field: 'cardNo',
          type: 'text',
          rules: [{ required: true, message: '身份证号必填', trigger: 'blur' }]
        },
        {
          label: '基本工资',
          field: 'basePay',
          type: 'text',
          unit: '元',
          rules: [{ required: true, message: '基本工资必填', trigger: 'blur' }]
        },
        // {
        //   label: '年限工资',
        //   field: 'lifePay',
        //   type: 'text',
        //   unit: '元',
        //   rules: [{ required: true, message: '年限工资必填', trigger: 'blur' }]
        // },
        // {
        //   label: '持证工资',
        //   field: 'certPay',
        //   type: 'text',
        //   unit: '元',
        //   rules: [{ required: true, message: '持证工资必填', trigger: 'blur' }]
        // },
        {
          label: '职称工资',
          field: 'titlePay',
          type: 'text',
          unit: '元',
          rules: [{ required: true, message: '职称工资必填', trigger: 'blur' }]
        },
        {
          label: '职位工资',
          field: 'postPay',
          type: 'text',
          unit: '元',
          rules: [{ required: true, message: '职位工资必填', trigger: 'blur' }]
        }
        // {
        //   label: '工资系数',
        //   field: 'coefficient',
        //   type: 'text',
        //   rules: [{ required: true, message: '工资系数必填', trigger: 'blur' }]
        // }
      ],
      formBtns: [
        {
          content: '创建',
          width: '185px',
          type: 'primary'
        },
        {
          content: '重置',
          width: '185px',
          type: ''
        }
      ]
    }
  },
  created() {
    this.getMemberList()
  },
  methods: {
    // 查看所有员工
    getMemberList() {
      let params = {
        personName: this.personName
      }
      memberList(params).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.formConfig[0].payload = res.data.map((el) => {
              return {
                value: el.personId,
                label: el.personName
              }
            })
          }
        }
      })
    },
    submitFn(formData) {
      console.log(formData, '信息创建')
      if (this.submited) return
      this.$loading({
        text: '信息添加中'
      })
      let empinfo = JSON.parse(JSON.stringify(formData))
      console.log(empinfo)
      addWages(empinfo).then((res) => {
        console.log(res.data, '创建')
        if (res.code == 200) {
          this.submited = true
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.$router.go(-1)
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
      this.$loading().close()
    }
  }
}
</script>

<style></style>
